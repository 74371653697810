import React, { Component } from 'react'

export default class DisplayDiagram extends Component {
    render() {

        return (
            <div>

                {this.props.diagram}

            </div>
        );
    };
};
